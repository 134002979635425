<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('custom_notifications.custom_notifications') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>


      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="d-flex col-lg-12 mb-5">
                <b-form-checkbox size="lg" id="item_min_qty" v-model="data.item_min_qty" name="check-button" switch></b-form-checkbox>
                <label for="item_min_qty" class="pl-2 pr-2">{{ $t('custom_notifications.item_min_qty') }}</label>
              </div>
              <div class="d-flex col-lg-12 mb-5">
                <b-form-checkbox size="lg" id="installment_due_date" v-model="data.installment_due_date" name="check-button" switch></b-form-checkbox>
                <label for="installment_due_date" class="pl-2 pr-2">{{ $t('custom_notifications.installment_due_date') }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('custom_notifications.interested_roles') }}</label>
                <div class="input-group mb-3">

                  <multiselect v-model="inner_interested_roles"
                               label="name"
                               track-by="id"
                               :placeholder="$t('custom_notifications.interested_roles')"
                               :options="interested_roles_list"
                               :multiple="true"
                               :taggable="true"
                               :class="validation && validation.interested_roles ? 'is-invalid' : ''"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/users/roles" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>

                <span v-if="validation && validation.interested_roles" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.interested_roles[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>


    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "from-custom-notifications",
  data() {
    return {
      mainRoute: 'settings/custom_notification',
      mainRouteDependency: 'base/dependency',

      data: {
        item_min_qty: false,
        installment_due_date: false,
        interested_roles: [],
      },
      isEditing: false,
      validation: null,
      inner_interested_roles: [],
      interested_roles_list: [],
    };
  },
  watch: {
    inner_interested_roles: function (val) {
      if (val) {
        this.data.interested_roles = [];
        this.data.interested_roles = val.map(row => row.id);
      }
    },
  },
  methods: {
    save() {
      this.create();
    },

    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getData() {
      ApiService.get(this.mainRoute).then((response) => {
        this.data.item_min_qty = response.data.data.item_min_qty;
        this.data.installment_due_date = response.data.data.installment_due_date;
        this.inner_interested_roles = response.data.data.inner_interested_roles;
        // this.inner_interested_roles = this.interested_roles_list.filter( row => response.data.data.interested_roles.includes(row.id));
      });
    },
    async getRolesList() {

      await ApiService.get(this.mainRouteDependency + "/roles").then((response) => {
        this.interested_roles_list = response.data.data.map((role) => {
          return {
            id: role.id,
            name: role.name,
          };
        });
      });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.custom_notifications")}]);
    // let promise1 = this.getRolesList();

    // Promise.all([promise1]).then(()=>{
    this.getData();
    this.getRolesList();
    // })


  },
};
</script>


